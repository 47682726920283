import { twMerge } from 'tailwind-merge';
import { ImageWithAltType } from '../types';
import { Image } from './Image';

function Mask({ image, className }: { image: ImageWithAltType; className?: string }) {
  if (!image?.asset) return null;

  return (
    <div className={twMerge(`relative flex aspect-square justify-end ${className}`)}>
      <svg viewBox="0 0 1 1" className="absolute">
        <defs>
          <clipPath clipPathUnits="objectBoundingBox" id="mask">
            <path d="M0.614296 1H0C0 1 0.04954 0.605213 0.348195 0.317109C0.646851 0.0290058 1 0 1 0V0.568584C1 0.568584 0.882419 0.598083 0.766454 0.720501C0.65049 0.84292 0.614296 1 0.614296 1Z" />
          </clipPath>
        </defs>
      </svg>
      <Image
        src={image}
        className="object-cover [clip-path:url(#mask)]"
        height={1000}
        width={1000}
        priority
      />
    </div>
  );
}

export default Mask;
