import { useEffect, useState } from 'react';
import Announcement from '../components/Announcement';
import HeroMask from '../components/HeroMask';
import Layout from '../components/Layout';
import Section from '../components/Section';
import AllArticlesButton from '../components/cards/AllArticlesButton';
import ArticleCard from '../components/cards/ArticleCard';
import ArticleCardBig from '../components/cards/ArticleCardBig';
import CampaignCard from '../components/cards/CampaignCard';
import NavigationCard from '../components/cards/NavigationCard';
import { homePageQuery } from '../groq/pages/home';
import { sanityClient } from '../lib/sanity.server';
import {
  CampaignSingularType,
  DefaultSEOType,
  FrontPageType,
  ImageWithAltType,
  MenuType,
  NewCarEntriesType,
  SettingsType,
} from '../types';
import cn from '../utils/cn';
import fetchSanityData from '../utils/fetchSanityData';

interface PageProps {
  data: {
    content: FrontPageType & { defaultSEO: DefaultSEOType };
    menus: MenuType;
    settings: SettingsType;
  };
}

type CampaignsOverrideType = {
  _id: string;
  overline?: string;
  title: string;
  description: string;
  image: ImageWithAltType;
};

function Frontpage({ data }: PageProps) {
  const {
    menus,
    content: {
      seo,
      defaultSEO,
      overline,
      title,
      primaryAction,
      secondaryAction,
      image,
      topTasksTitle,
      topTasks,
      campaignsTitle,
      newCarEntries,
      servicesTitle,
      services,
      callToAction,
      articlesTitle,
      featureArticles,
      announcement,
    },
    settings,
  } = data;

  const [isTransverseActive, setIsTransverseActive] = useState(false);
  const [useNewCarCampaignCard, setUseNewCarCampaignCard] = useState(false);
  const [campaigns, setCampaigns] = useState<CampaignSingularType[] | undefined>(undefined);
  const [newCarCampaignsCard, setNewCarCampaignsCard] = useState<CampaignsOverrideType | undefined>(
    undefined,
  );

  const brandCampaigns: Set<string> = new Set();
  const remainingCampaigns: CampaignSingularType[] = [];

  useEffect(() => {
    const fetchCampaigns: () => void = async () => {
      const response = await fetchSanityData('frontPageCampaigns');
      setIsTransverseActive(response.isTransverseActive);
      setUseNewCarCampaignCard(response.useNewCarCampaignCard);
      setCampaigns(response.campaigns);
      setNewCarCampaignsCard(response.newCarCampaignsCard);
    };

    fetchCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // my comment

  if (campaigns && campaigns?.length > 0) {
    campaigns.forEach((item) => {
      if (item.brand) {
        brandCampaigns.add(item.brand);
      } else {
        remainingCampaigns.push(item);
      }
    });
  }

  const useNewCarEntry =
    useNewCarCampaignCard && newCarCampaignsCard && !isTransverseActive && brandCampaigns.size > 0;

  return (
    <Layout menus={menus} seo={seo} settings={settings} defaultSEO={defaultSEO}>
      <HeroMask
        overline={overline}
        title={title}
        image={image}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      />

      {/* TOPP-OPPGAVER */}
      {!!topTasks?.length && (
        <Section color="background">
          <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">{topTasksTitle}</h2>
          <div className="grid gap-5 sm:grid-cols-6">
            {topTasks.map((item, index) => {
              const { length } = topTasks;

              if (!item.title || !item.href) return null;

              return (
                <div
                  key={item.title}
                  className={cn(
                    index < 2 ? 'sm:col-span-3' : 'sm:col-span-2',
                    length % 2 === 0 ? 'sm:col-span-3' : '',
                  )}
                >
                  <NavigationCard link={item} />
                </div>
              );
            })}
          </div>
        </Section>
      )}

      {/* KAMPANJER */}
      {(!!Array.from(brandCampaigns).length ||
        (Array.isArray(remainingCampaigns) && !!remainingCampaigns.length)) && (
        <Section>
          <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">{campaignsTitle}</h2>
          <div className="grid gap-5 sm:grid-cols-2 lg:grid-cols-1">
            {useNewCarEntry ? (
              <CampaignCard
                key={newCarCampaignsCard._id}
                overline={newCarCampaignsCard.overline}
                title={newCarCampaignsCard.title}
                description={newCarCampaignsCard.description}
                href="/kampanjer"
                image={newCarCampaignsCard.image}
              />
            ) : (
              Array.from(brandCampaigns)
                .sort()
                .map((brand) => {
                  return <EntryByBrand key={brand} brand={brand} newCarEntries={newCarEntries} />;
                })
            )}
            {remainingCampaigns.map((campaign) => {
              return (
                <CampaignCard
                  key={campaign._id}
                  overline={campaign.overline}
                  title={campaign.title}
                  description={campaign.pitch}
                  href={campaign.href || '/kampanjer'}
                  image={campaign.image}
                />
              );
            })}
          </div>
        </Section>
      )}

      {/* TJENESTER */}
      {services && !!services.length && (
        <Section color="info">
          <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">{servicesTitle}</h2>
          <div className="grid gap-5 lg:grid-cols-3">
            {services.map((service) => {
              return <NavigationCard key={service.title} link={service} color="info" />;
            })}
          </div>
        </Section>
      )}

      {/* CTA */}
      {callToAction?.title && (
        <Section>
          <ArticleCardBig
            overline={callToAction.overline}
            title={callToAction.title}
            description={callToAction.description}
            href={callToAction.link.href}
            linkText={callToAction.link.linkText}
            image={callToAction.image}
          />
        </Section>
      )}

      {/* ARTIKLER */}
      {!!featureArticles.length && (
        <Section>
          <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">{articlesTitle}</h2>

          <div className="mb-10 grid gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {featureArticles.map((article) => {
              return <ArticleCard key={article.title} article={article} />;
            })}
          </div>
          <AllArticlesButton />
        </Section>
      )}

      {/* ANNOUNCEMENT */}
      {announcement?.title && (
        <Section>
          <Announcement value={announcement} />
        </Section>
      )}
    </Layout>
  );
}

function EntryByBrand({
  brand,
  newCarEntries,
}: {
  brand: string;
  newCarEntries: NewCarEntriesType;
}) {
  if (!newCarEntries[brand]) return null;

  return (
    <CampaignCard
      key={newCarEntries[brand].title}
      overline={newCarEntries[brand].overline}
      title={newCarEntries[brand].title}
      description={newCarEntries[brand].description}
      image={newCarEntries[brand].image}
      href={newCarEntries[brand].externalLandingPage || `/kjope-bil/nybil/${brand}`}
    />
  );
}

export async function getStaticProps() {
  const data = await sanityClient.fetch(homePageQuery);

  if (!data.content) return { notFound: true };

  return {
    props: { data },
    revalidate: 1,
  };
}

export default Frontpage;
