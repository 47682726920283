import Link from 'next/link';
import cn from '../../utils/cn';
import Icon, { Icons } from '../icons/Icon';

interface NavigationCardProps {
  link: { title: string; description: string; href: string };
  color?: 'info' | 'beige-dark' | 'beige-light';
}

function NavigationCard({ link, color = 'beige-light' }: NavigationCardProps) {
  const getColor = () => {
    switch (color) {
      case 'info':
        return 'hover:bg-[#aac8ff] active:bg-[#83afff] bg-[#f7f9ff] border-[#83afff]/20';
      case 'beige-light':
        return 'hover:bg-[#ddd9d3] active:bg-[#ada9a5] bg-[#fefdfd] border-[#ada9a5]/20';
      case 'beige-dark':
        return 'hover:bg-[#ddd9d3] active:bg-[#ada9a5] bg-background border-[#ada9a5]/20';
      default:
        return '';
    }
  };

  return (
    <div
      className={cn(
        'group relative flex h-full cursor-pointer flex-col justify-between gap-2 rounded-sm p-6 outline outline-transparent duration-300 lg:p-8',
        getColor(),
      )}
    >
      <div className="flex flex-col gap-2">
        <Link
          href={link.href || ''}
          className="after:contents:'' after:absolute after:inset-0 after:outline after:outline-1 after:outline-transparent focus:outline-none focus:focus-visible:after:outline-black"
        >
          <span className=" text-2xl font-bold text-[#001946]">{link.title}</span>
        </Link>
        <p className="text-[#001232] lg:text-lg">{link.description}</p>
      </div>
      <Icon icon={Icons.ARROW_RIGHT} className="h-6 w-6 place-self-end" />
    </div>
  );
}

export default NavigationCard;
